#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
 
 body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } 
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    /* text-align: center; */
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-next , .swiper-button-prev {
    color:white; /* Set the color to white */
  font-size: 40px; /* Adjust size if needed */
  width: 60px;
  height: 60px;
  border-radius: 50%; /* Create a circle */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #ffffff; */
  }
  
  .swiper-button-next::after, .swiper-button-prev::after {
    font-size: 40px; 
  }
  /* .swiper-button-next{
    top: 50px;
    left: 68px;
  }
  .swiper-button-prev{
    top: 51px;
    border: 2px;
  } */

  