#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
/* Hide Swiper navigation buttons */
/* .swiper-button-prev,
.swiper-button-next {
  display: none !important;
} */

/* Hide play/pause buttons (if any) */
video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pause-button,
video::-webkit-media-controls-rewind-button,
video::-webkit-media-controls-return-to-realtime-button {
  display: none !important;
}

/* For non-WebKit browsers (like Firefox) */
video::media-controls {
  display: none !important;
}

  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    /* text-align: center; */
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-next , .swiper-button-prev {
  color: #ffffff; /* Set the color to white */
  font-size: 26px; /* Adjust size if needed */
  width: 50px;
  height: 50px;
  border-radius: 50%; /* Create a circle */
  /* background-color: rgba(0, 0, 0, 0.2);  */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  }
  
  .swiper-button-next::after, .swiper-button-prev::after {
    font-size: 26px !important; 
  }
  .swiper-button-next{
    top: 50px;
    left: 180px;
  }
  .swiper-button-prev{
    top: 51px;
    border: 2px;
    left: 91px;
    border: 2px solid #ffffff;
  }

  @media(max-width:430px){
    .swiper-button-prev{
      left: 30px;
    }
    .swiper-button-next{
      left: 100px;
    }
    .swiper-button-next::after, .swiper-button-prev::after {
      font-size: 20px !important; 
    }
  }

  